<template>
  <!-- 资产管理 -->
  <div class="manage">
    <!-- 面包屑 -->
    <!-- Tab -->
    <div class="from_tab">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleClick"
        stretch
      >
        <el-tab-pane label="中信账户绑卡" name="first" v-if="isfirst">
          <div class="tianjia" v-if="zjindex == 0">
            <div class="kuang_yinhang"></div>
            <!-- <div class="no">暂 无 银 行 卡</div> -->
            <div class="btn" @click="addBank">去添加</div>
          </div>
          <div class="edit" v-if="zjindex == 1">
            <el-form ref="bankForm" :model="bankForm" :rules="bankRules">
              <el-form-item label="" prop="holderName">
                <i class="hy-icon-yonghuming"></i>
                <el-input
                  disabled
                  v-model="bankForm.holderName"
                  placeholder="请输入公司名称"
                  maxlength="20"
                  type="text"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="bankName">
                <i class="hy-icon-yinhang"></i>
                <el-input
                  v-model="bankForm.bankName"
                  placeholder="请输入开户行名称"
                  maxlength="50"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="holderIdcard">
                <i class="hy-icon-shenfenzheng"></i>
                <el-input
                  v-model="bankForm.holderIdcard"
                  placeholder="请输入纳税人识别号(统一社会信用代码)"
                  maxlength="20"
                ></el-input>
              </el-form-item>

              <el-form-item label="" prop="bankCardNo">
                <i class="hy-icon-shouji"></i>
                <el-input
                  v-model="bankForm.bankCardNo"
                  placeholder="请输入对公账号"
                  maxlength="30"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="holderTelephone">
                <i class="hy-icon-shouji"></i>
                <el-input
                  v-model="bankForm.holderTelephone"
                  placeholder="请输入手机号码"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="code" class="pr">
                <i class="hy-icon-yanzhengma"></i>
                <el-input
                  style="width: 224px"
                  placeholder="请输入验证码"
                  v-model="bankForm.code"
                  maxlength="6"
                ></el-input>
                <button
                  @click.prevent="getCode()"
                  class="code-btn"
                  :disabled="!show"
                >
                  <span v-show="show">获取验证码</span>
                  <span v-show="!show" class="count">{{ count }} s</span>
                </button>
              </el-form-item>

              <el-button type="primary" @click="onBankSubmit" class="bangding"
                >提交绑定</el-button
              >
            </el-form>
          </div>
          <div class="show" v-if="zjindex == 2">
            <el-button type="primary" @click="tjyxk" class="bank"
              >添加银行卡</el-button
            >
            <div class="box-cards">
              <div class="banks" v-for="(item, index) in readlist" :key="index">
                <div class="yihang">
                  <!-- <span class="bankName" >{{ item.bankName }}</span> -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.bankName"
                    placement="top-start"
                  >
                    <el-button class="bankName">{{ item.bankName }}</el-button>
                  </el-tooltip>
                  <div>
                    <el-button
                      type="primary"
                      @click="BanknameClick(item, 0)"
                      class="jiebang"
                      >解绑</el-button
                    >
                    <i
                      class="el-icon-view"
                      @click="item.istrue = !item.istrue"
                    ></i>
                  </div>
                </div>
                <div class="show_number" v-if="item.istrue">
                  {{ item.bankCardNo | hideMiddle }}
                </div>
                <div class="show_number" v-if="!item.istrue">
                  {{ item.bankCardNo }}
                </div>
                <div class="card-info">
                  <div class="card-name bankName">{{ item.holderName }}</div>
                  <div class="card-date">{{ item.createTime }}</div>
                </div>
              </div>
              <!-- <div slot="header" class="clearfix"></div> -->
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="中金账户绑卡" name="second" v-if="issecond">
          <div class="tianjia" v-if="yihangindex == 0">
            <div class="kuang_yinhang"></div>
            <div class="btn" @click="addBanks">去添加</div>
          </div>
          <div v-if="yihangindex == 1">
            <div class="Applications">
              <div style="margin-right: 5px">申请状态:{{ bindingStatus }}</div>
              <i class="el-icon-refresh icons" @click="RefresshClick"></i>
            </div>
            <div class="edit">
              <el-form
                ref="bankFormconfig"
                :model="bankFormconfig"
                :rules="bankFormconfigRules"
              >
                <el-form-item label="" prop="credentialNumber">
                  <i class="hy-icon-yonghuming"></i>
                  <el-input
                    v-model="bankFormconfig.credentialNumber"
                    placeholder="身份证号"
                    maxlength="20"
                    type="text"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="bankID">
                  <i class="hy-icon-yinhang"></i>
                  <el-select
                    v-model="bankFormconfig.bankID"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.bankId"
                      :label="item.bankName"
                      :value="item.bankId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="bankAccountName">
                  <i class="hy-icon-shenfenzheng"></i>
                  <el-input
                    v-model="bankFormconfig.bankAccountName"
                    placeholder="银行账户名称"
                    maxlength="20"
                  ></el-input>
                </el-form-item>

                <el-form-item label="" prop="bankAccountNumber">
                  <i class="hy-icon-yinhang"></i>
                  <el-input
                    v-model="bankFormconfig.bankAccountNumber"
                    placeholder="银行账户号码"
                    maxlength="30"
                    type="number"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="bankPhoneNumber">
                  <i class="hy-icon-shouji"></i>
                  <el-input
                    v-model="bankFormconfig.bankPhoneNumber"
                    placeholder="银行卡预留手机号码"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
                <el-button
                  type="primary"
                  @click="onBankSubmits"
                  class="bangding"
                  >下一步</el-button
                >
              </el-form>
            </div>
          </div>

          <div class="show" v-if="yihangindex == 2">
            <el-button type="primary" @click="tjyxks" class="bank"
              >添加银行卡</el-button
            >
            <div class="box-cards">
              <div class="banks" v-for="(item, index) in readlist" :key="index">
                <div class="yihang">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.bankName"
                    placement="top-start"
                  >
                    <el-button class="bankName">{{ item.bankName }}</el-button>
                  </el-tooltip>
                  <div>
                    <el-button
                      type="primary"
                      @click="BanknameClick(item, 1)"
                      class="jiebang"
                      >解绑</el-button
                    >
                    <i
                      class="el-icon-view"
                      @click="item.istrue = !item.istrue"
                    ></i>
                  </div>
                </div>
                <div class="show_number" v-if="item.istrue">
                  {{ item.bankCardNo | hideMiddle }}
                </div>
                <div class="show_number" v-if="!item.istrue">
                  {{ item.bankCardNo }}
                </div>
                <div class="card-info">
                  <div class="card-name bankName">{{ item.holderName }}</div>
                  <div class="card-date">{{ item.createTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="平台账户" name="third">
          <div v-if="CICC">
            <div class="tianjias">
              <!-- <div class="no">暂 未 认 证</div> -->
              <div style="display: flex; justify-content: right">
                <!-- <div class="btn1" @click="tianjiaClick" v-if="isbankType==1||isbankType==null" style="margin-right: 20px;">开通中信账户</div> -->
                <el-button
                  type="primary"
                  @click="tianjiaClick"
                  v-if="isbankType == 1 || isbankType == null"
                  style="margin-right: 20px"
                  >开通中信银行账户</el-button
                >
                <!-- <el-button type="primary" @click="CICCClick" v-if="isbankType==0||isbankType==null"
                  style="margin-right: 20px;">开通兴业银行账户</el-button> -->
              </div>
              <div
                class="kuang_pingtai"
                style="margin: 100px auto"
                v-if="accountlist.length == 0 && zhanghuindex != 1"
              ></div>
            </div>
          </div>

          <div class="edit" v-if="zhanghuindex == 1">
            <el-form
              ref="platformFrom"
              :model="platformFrom"
              :rules="platformRules"
            >
              <el-form-item label="法人名称" prop="name">
                <el-input
                  v-model="platformFrom.name"
                  placeholder="请输入法人名称"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="法人身份证号" prop="numbers">
                <i class="hy-icon-mima"></i>
                <el-input
                  v-model="platformFrom.numbers"
                  placeholder="请输入法人身份证号"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <el-form-item label="统一信用代码" prop="ids">
                <i class="hy-icon-yonghuming"></i>
                <el-input
                  v-model="platformFrom.ids"
                  placeholder="请输入统一信用代码"
                  :disabled="true"
                ></el-input>
              </el-form-item>

              <el-button type="primary" @click="onSubmit" class="bangding"
                >提交</el-button
              >
            </el-form>
          </div>

          <div v-if="zhanghuindex == 3">
            <div class="platform">
              <div class="box-cards">
                <div
                  class="banks"
                  v-for="(item, index) in accountlist"
                  :key="index"
                >
                  <div class="flex" v-if="item.bankType == 0">
                    <div style="margin-right: 20px">
                      <el-button type="danger" @click="logoutClick"
                        >注销账户</el-button
                      >
                    </div>
                    <div>{{ item.accountName }}</div>
                  </div>

                  <div style="margin-top: 30px; text-align: left" v-else>
                    <div>{{ item.accountName }}</div>
                  </div>
                  <div style="margin-top: 30px; text-align: left">
                    账号：{{ item.accountNum }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="CICC">
            <div class="tianjia">
              <div class="kuang_pingtai"></div>
              <div class="no">暂 未 认 证</div>
              <div class="btn1" @click="tianjiaClick"  v-if="isbankType==1||isbankType==null">去认证</div>
            </div>
            <div class="edit" v-if="zhanghuindex == 1">
              <el-form
                ref="platformFrom"
                :model="platformFrom"
                :rules="platformRules"
              >
                <el-form-item label="法人名称" prop="name">
                  <el-input
                    v-model="platformFrom.name"
                    placeholder="请输入法人名称"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="法人身份证号" prop="numbers">
                  <i class="hy-icon-mima"></i>
                  <el-input
                    v-model="platformFrom.numbers"
                    placeholder="请输入法人身份证号"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
                <el-form-item label="统一信用代码" prop="ids">
                  <i class="hy-icon-yonghuming"></i>
                  <el-input
                    v-model="platformFrom.ids"
                    placeholder="请输入统一信用代码"
                    :disabled="true"
                  ></el-input>
                </el-form-item>

                <el-button type="primary" @click="onSubmit" class="bangding"
                  >提交</el-button
                >
              </el-form>
            </div>
            <div class="logout">
              <div
                v-if="zhanghuindex == 3"
                style="margin-right: 20px; float: right"
              >
                <el-button type="primary" @click="CICCClick" v-if="isbankType==1||isbankType==null"
                  >开通中金账户</el-button
                >
              </div>
              <div v-if="zhanghuindex == 3">
                <div class="platform">
                  <div class="box-cards">
                    <div
                      class="banks"
                      v-for="(item, index) in accountlist"
                      :key="index"
                    >
                      <div class="flex"  v-if="item.bankType==0">
                        <div
                          style="margin-right: 20px"
                        >
                          <el-button type="danger" @click="logoutClick"
                            >注销账户</el-button
                          >
                        </div>
                        <div>{{ item.accountName }}</div>
                      </div>

                      <div style="margin-top: 30px;text-align: left;"  v-else>
                     
                        <div>{{ item.accountName }}</div>
                      </div>
                      <div style="margin-top: 30px;text-align: left;">
                        账号：{{ item.accountNum }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!CICC">
            <stop1></stop1>
          </div> -->
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="请输入交易密码"
        center
        width="30%"
        :visible.sync="dialogTableVisible"
      >
        <div style="text-align: center">
          <paycode-app @getPwd="getPwd"></paycode-app>
        </div>
      </el-dialog>
      <el-dialog
        title="请输入交易密码"
        center
        width="30%"
        :visible.sync="dialogTableVisible1"
      >
        <div style="text-align: center">
          <paycode-app :id="2" @getPwd="getPwd1"></paycode-app>
        </div>
      </el-dialog>
    </div>
    <InputPasswordDialog
      :isShow="passWordIsShow"
      :title="title"
      :cardId="cardId"
      @funt="funt"
      @close="closeDialog('passWordIsShow')"
    ></InputPasswordDialog>

    <InputPasswordDialog
      :isShow="passWordIsShows"
      :title="title"
      @binding="binding"
      @close="closeDialog('passWordIsShows')"
    ></InputPasswordDialog>
    <!-- 注销账户 -->
    <InputPasswordDialog
      :isShow="islogout"
      ref="InputPasswordDialog"
      :title="title"
      @logoutHeader="logoutHeader"
      @close="closeDialog('islogout')"
    ></InputPasswordDialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="400px"
      custom-class="error-password"
      @before-close="dialogVisible = false"
    >
      <div class="content">
        <div class="left">
          <i class="hy-icon-cuowu"></i>
        </div>
        <div class="right">支付密码错误,请重试</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="rechecked"
          >重试</el-button
        >
      </span>
    </el-dialog>

    <!-- ///////////// -->
    <el-dialog
      title="提示"
      :visible.sync="bindingVisible"
      width="600px"
      custom-class="error-password"
    >
      <div class="flexs Application">
        <div style="margin-right: 5px">绑卡状态:{{ statusTitle }}</div>
        <i class="el-icon-refresh icons" @click="RefresshClick"></i>
      </div>
      <div class="content">
        <div style="font-size: 19px; margin-right: 10px">打款验证</div>
        <div>
          <el-input
            v-model="inputCode"
            placeholder="请输入银行打款金额进行验证"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="bindingVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="bindingClick"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  reqSendCode,
  reqCompanyAuthDetail,
  registerAndAddWallet,
  cancelBankAccount,
  reqIsPayBonds,
} from "@/api/hy";
import { financeWallet } from "@/api/home";
import md5 from "js-md5";
import {
  financeBankCard,
  bankCardList,
  financeCapitalAccount,
  financeBankCards,
} from "@/api/public";

import {
  listAccount,
  unbindCardForZJ,
  listBankCode,
  getAccountStatusForZJ,
  bindCardConfirmForZJ,
  bindCardForZJ,
  getCardBindStatusForZJ,
  getAccountBalanceByParam,
} from "@/api/Accountopening";
import Vue from "vue";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import * as types from "@/store/action-types";

export default {
  data() {
    return {
      bindingStatus: "",
      inputCode: "",
      bankTypeCode: null,
      bindingVisible: false,
      statusTitle: "未申请",
      options: [],
      bankFormconfig: {
        credentialNumber: "",
        bankID: "",
        bankAccountName: "",
        bankAccountNumber: "",
        bankPhoneNumber: "",
        ifOpenAccount: "0",
      },
      bankFormconfigRules: {
        credentialNumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern:
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效法人身份证号",
            trigger: "blur",
          },
        ],
        bankID: [
          { required: true, message: "请选择绑定银行", trigger: "change" },
        ],
        bankAccountName: [
          {
            required: true,
            message: "请输入银行账户名称(企业同名账户)",
            trigger: "blur",
          },
        ],
        bankAccountNumber: [
          { required: true, message: "请输入银行账户号码", trigger: "blur" },
        ],
        bankPhoneNumber: [
          {
            required: true,
            message: "请输入银行卡预留手机号码",
            trigger: "blur",
          },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
      },
      isbankType: null,
      CICC: true,
      dialogVisible: false,
      islogout: false,
      accountlist: [],
      cardId: "",
      passWordIsShows: false,
      passWordIsShow: false,
      title: "请输入密码",
      activeName: "first",
      zjindex: 0,
      yihangindex: 0,
      zhanghuindex: 0,
      show: "false",
      count: "",
      message: "",
      readlist: [],
      bankForm: {
        holderName: "",
        holderIdcard: "",
        bankName: "",
        bankCardNo: "",
        holderTelephone: "",
        code: "",
      },
      config: {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        ifEncrypt: false,
        bankType: "0",
      },
      bankRules: {
        holderName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        holderIdcard: [
          {
            required: true,
            message: "请输入纳税人识别号(统一社会信用代码) ",
            trigger: "blur",
          },
        ],
        bankName: [
          { required: true, message: "请输入开户行名称", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5 ]{2,50}$/,
            message: "请输入开户行名称",
            trigger: "blur",
          },
        ],
        bankCardNo: [
          { required: true, message: "请输入对公账号", trigger: "blur" },
          // {
          //   pattern: /^([1-9]{1})(\d{15}|\d{18})$/,
          //   message: "请输入正确对公账号",
          //   trigger: "blur",
          // },
        ],
        holderTelephone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      platformFrom: {
        name: "",
        numbers: "",
        ids: "",
      },
      platformRules: {
        name: [{ required: true, message: "请输入法人名称", trigger: "blur" }],
        numbers: [
          { required: true, message: "请输入证件类型", trigger: "blur" },
        ],
        ids: [
          { required: true, message: "请输入统一信用代码", trigger: "blur" },
        ],
      },
      num: "1234 **** **** 4321",
      dialogTableVisible: false,
      dialogTableVisible1: false,
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/manage", title: "我的银行卡" },
      ],
      type: false,
      password: "",
      bindCardTxsn: "",
      isfirst: false,
      issecond: false,
      isge: false,
    };
  },
  components: {
    InputPasswordDialog: () => import("../components/InputPasswordDialog.vue"),
    stop1: () => import("./components/stop1.vue"),
  },
  created() {
    // this.isAuthentication = window.localStorage.getItem("userName");
    // this.bankForm.holderName = window.localStorage.getItem("userName");
    // if (window.localStorage.getItem("userName")) {
    //   this.zhanghuindex = 1;
    //   this.getCompanyDetail();
    // } else {
    //   this.zhanghuindex = 0;
    // }
    this.getlistAccount();
    this.bankCardList();
    this.getAccountBalanceBy();
  },

  filters: {
    hideMiddle(val) {
      return `${val.substring(0, 4)} ****  **** ${val.substring(
        val.length - 4
      )}`;
    },
  },
  methods: {
    geitWallet() {
      financeWallet({
        enterpriseId: Vue.ls.get(types.enterpriseId),
      }).then((res) => {
        if (res.code == 0) {
          this.isge = res.data;
        }
      });
    },
    getAccountBalanceBy() {
      let config = {
        searchType: 0,
      };
      getAccountBalanceByParam(config).then((res) => {
        console.log(res, "res,resresresresresres");
        if (res.code == 0) {
          let list = res.data;
          for (let i = 0; i < list.length; i++) {
            if (list[i].bankType == 0) {
              this.isfirst = true;
              this.activeName = "first";
            }
            if (list[i].bankType == 1) {
              this.issecond = true;
              this.activeName = "second";
            }
            if (list.length > 1) {
              this.activeName = "first";
            }
            if (list.length == 0) {
              this.activeName = "third";
            }
          }
          console.log(this.isfirst, this.issecond);
        }
      });
    },
    getStatusForZJ(va) {
      let config = {};
      getCardBindStatusForZJ(config).then((res) => {
        this.bindCardTxsn = res.data.bindCardTxsn;
        this.bindingStatus = res.data.bindCardStatus;
        if (res.data.bindCardParam) {
          this.bankFormconfig = res.data.bindCardParam;
        }
        if (res.data.bindCardStatus == 10) {
          this.bindingStatus = "处理中";
        }
        if (res.data.bindCardStatus == 17) {
          this.bindingStatus = "待被动打款验证";
        }
        if (res.data.bindCardStatus == 18) {
          this.bindingStatus = "被动已打款待验证";
          this.bindingVisible = true;
          if (!va) {
            this.$message({
              message: res.message,
              type: "绑卡成功",
            });
          }
        }
        if (res.data.bindCardStatus == 40) {
          this.bindingStatus = "绑卡失败";
        } else {
          this.bindingStatus = "未申请";
        }

        if (res.data.confirmCardStatus == 20) {
          this.statusTitle = "处理中";
        }
        if (res.data.confirmCardStatus == 30) {
          this.statusTitle = "确认绑卡成功";
        }
        if (res.data.confirmCardStatus == 40) {
          this.statusTitle = "确认绑卡失败";
        } else {
          this.statusTitle = "未申请";
        }
      });
    },
    RefresshClick() {
      this.getStatusForZJ(1);
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    getlistBankCode() {
      let config = {
        pageNum: 1,
        pageSize: 999,
      };
      listBankCode(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options = res.data.list;
        }
      });
    },
    CICCClick() {
      // this.CICC=false
      this.$router.push({
        path: "/opencicc",
      });
    },
    rechecked() {
      this.dialogVisible = false;
      this.islogout = true;
      this.$refs.InputPasswordDialog.clearPassword();
    },
    logoutHeader(data) {
      console.log(data);
      let config = {
        tradePwd: md5(data),
      };
      cancelBankAccount(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "注销成功!",
          });
          this.bankCardList();
        } else {
          this.$message.error(res.message);
          // this.dialogVisible=true
        }
      });
    },

    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      debugger;
      if (status == 0) {
        let id = Vue.ls.get(types.enterpriseId);
        reqIsPayBonds(id).then((res) => {
          console.log(res);
          if (res.data) {
            this.islogout = true;
          } else {
            this.$confirm("暂无设置交易密码, 是前往设置?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/finance");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        });
      } else {
        alert(msg);
        return false;
      }
    },
    //注销账户
    logoutClick() {
      this.geitWallet()
      if (!this.beforhandle("personalCenter:bank:account:cancel")) return;
      this.$confirm("确定要注销账户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!this.isge) {
            this.$message.error('您未设置交易密码,请前往"账户安全"设置!');
            this.islogout = false;
            return false;
          }
          let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
          if (checkUkeyWhite == 0) {
            ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
          } else {
            this.islogout = true;
          }
          // ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消注销",
          });
        });
    },
    binding(data) {
      this.bankForm.tradePassword = md5(data);
      financeBankCard(this.bankForm).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "添加成功",
          });
          this.bankCardList();
          this.bankForm = {
            holderName: "",
            holderIdcard: "",
            bankName: "",
            bankCardNo: "",
            holderTelephone: "",
            code: "",
          };
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },

    funt(data) {
      this.password = data;
      let config = {
        bankType: this.bankTypeCode,
        cardId: this.cardId,
        password: md5(this.password),
      };
      financeBankCards(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "解绑成功",
          });

          this.bankCardList();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    BanknameClick(item, val) {
      this.bankTypeCode = val;
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      this.cardId = item.cardId;
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
      } else {
        this.passWordIsShow = true;
      }
    },
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShow = true;
      } else {
        alert(msg);
        return false;
      }
    },

    bankCardList() {
      this.isAuthentication = window.localStorage.getItem("userName");
      this.bankForm.holderName = window.localStorage.getItem("userName");
      this.getCompanyDetail();
      let config = this.config;
      bankCardList(config).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          list.forEach((item) => {
            item.createTime = item.createTime.replace("T", " ");
            item.istrue = true;
          });
          this.readlist = list;
          if (this.config.bankType == "1") {
            if (res.data.length > 0) {
              this.yihangindex = 2;
            } else {
              this.yihangindex = 0;
            }
          }
          if (this.config.bankType == "0") {
            if (res.data.length > 0) {
              this.zjindex = 2;
            } else {
              this.zjindex = 0;
            }
          }
          console.log(this.tabIndex);
          console.log(this.readlist);
        }
      });
    },
    getlistAccount() {
      let config = {};
      listAccount(config).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            this.accountlist = res.data;

            this.accountlist.forEach((item) => {
              if (item.bankType == 1) {
                this.isbankType = 1;
              }
              if (item.bankType == 0) {
                this.isbankType = 0;
              }
            });
            if (this.accountlist.length == 0) {
              this.isbankType = null;
            } else if (this.accountlist.length == 2) {
              this.isbankType = 3;
            }
          }
          this.zhanghuindex = 3;
        }
        console.log(res);
      });
    },
    async getCompanyDetail() {
      const ret = await reqCompanyAuthDetail({
        enterpriseId: Vue.ls.get(types.enterpriseId),
      });
      if (ret.code == 0) {
        this.platformFrom.name = ret.data.legalName;
        this.platformFrom.numbers = ret.data.legalIdcard;
        this.platformFrom.ids = ret.data.socialCode;
        this.bankForm.holderIdcard = ret.data.socialCode;
      }
      // this.getFirmtype();
    },
    // checkPhone() {
    //   if (this.bankForm.holderTelephone) {
    //     if (!this.$verify.phone(this.bankForm.holderTelephone)) {
    //       this.errorType = 11;
    //       this.$YTmsg("请填写正确的手机号", "error");
    //       return;
    //     }
    //   }
    // },
    JsESACheckIsKeys(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShows = true;
      } else {
        alert(msg);
        return false;
      }
    },
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.bindingVisible = true;
      } else {
        alert(msg);
        return false;
      }
    },
    bindingClick() {
      if (this.inputCode == "") {
        this.$message.error("请输入打款金额");
        return false;
      }
      let config = {
        amount: this.inputCode,
        bindCardTxsn: this.bindCardTxsn,
      };
      bindCardConfirmForZJ(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getStatusForZJ();
          this.bindingVisible = false;
          this.yihangindex = 2;
          this.bankCardList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 中金
    onBankSubmits() {
      // this.bindingVisible = true;
      this.$refs["bankFormconfig"].validate((valid) => {
        if (valid) {
          let config = this.bankFormconfig;
          bindCardForZJ(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getStatusForZJ();
              let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
              if (checkUkeyWhite == 0) {
                ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
              } else {
                this.bindingVisible = true;
              }
            } else {
              this.$message.error(res.message);
            }
          });
          // this.passWordIsShows = true;

          // financeBankCard(this.bankForm).then((res) => {
          //   console.log(res);
          //   if (res.code == 0) {
          //     this.$message({
          //       type: "success",
          //       message: "添加成功",
          //     });
          //     this.bankCardList();
          //     this.bankForm = {
          //       holderName: "",
          //       holderIdcard: "",
          //       bankName: "",
          //       bankCardNo: "",
          //       holderTelephone: "",
          //       code: "",
          //     };
          //   } else {
          //     this.$message({
          //       type: "error",
          //       message: res.message,
          //     });
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 中信添加银行卡
    onBankSubmit() {
      this.$refs["bankForm"].validate((valid) => {
        if (valid) {
          // this.passWordIsShows = true;
          let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
          if (checkUkeyWhite == 0) {
            ESASignClient.ESACheckIsKey(this.JsESACheckIsKeys, null, false);
          } else {
            this.passWordIsShows = true;
          }

          // financeBankCard(this.bankForm).then((res) => {
          //   console.log(res);
          //   if (res.code == 0) {
          //     this.$message({
          //       type: "success",
          //       message: "添加成功",
          //     });
          //     this.bankCardList();
          //     this.bankForm = {
          //       holderName: "",
          //       holderIdcard: "",
          //       bankName: "",
          //       bankCardNo: "",
          //       holderTelephone: "",
          //       code: "",
          //     };
          //   } else {
          //     this.$message({
          //       type: "error",
          //       message: res.message,
          //     });
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    // tab切换
    handleClick(index) {
      this.tabIndex = index.index;
      console.log(index.index);
      if (index.index != "2") {
        this.config.bankType = index.index;
        this.bankCardList();
      } else {
        this.config.bankType = "";
      }
    },
    onSubmit() {
      // registerAndAddWallet
      let config = {
        certType: "1",
        legalName: this.platformFrom.name,
        socialCode: this.platformFrom.ids,
      };
      registerAndAddWallet(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.data,
            type: "success",
          });
          this.bankCardList();
        } else {
          this.$message.error(res.message);
        }
      });
      // this.$router.push({
      //   path: "/authentication",
      // });
    },
    addBank() {
      if (!this.beforhandle("personalCenter:bank:myBank:add")) return;
      this.bankForm.holderName = window.localStorage.getItem("userName");
      this.getCompanyDetail();
      this.zjindex = 1;
    },
    addBanks() {
      // if (!this.beforhandle("personalCenter:bank:myBank:add")) return;
      // this.bankForm.holderName = window.localStorage.getItem("userName");
      this.getlistBankCode();
      this.yihangindex = 1;
    },
    tianjiaClick() {
      this.zhanghuindex = 1;
      // if (!this.beforhandle("personalCenter:bank:account:auth")) return;
      // this.$router.push({
      //   path: "/authentication",
      // });
    },
    tjyxk() {
      if (!this.beforhandle("personalCenter:bank:myBank:add")) return;
      this.bankForm.holderName = window.localStorage.getItem("userName");
      this.getCompanyDetail();
      this.show = true;
      this.count = 0;
      this.zjindex = 1;
      // this.zhanghuindex = 1;
    },
    tjyxks() {
      // if (!this.beforhandle("personalCenter:bank:myBank:add")) return;
      // this.bankForm.holderName = window.localStorage.getItem("userName");
      // this.show = true;
      // this.count = 0;
      this.yihangindex = 1;
      this.getlistBankCode();
      this.getStatusForZJ();
      // this.zhanghuindex = 1;
    },
    getCode() {
      //axios请求
      if (!this.bankForm.holderTelephone) {
        this.$YTmsg("请输入手机号码", "error");
        return false;
      }
      if (!this.$verify.phone(this.bankForm.holderTelephone)) {
        this.errorType = 11;
        this.$YTmsg("请填写正确的手机号", "error");
        return false;
      }

      reqSendCode({ phone: this.bankForm.holderTelephone })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "验证码已发送，请注意查收",
            });

            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPwd(str) {
      console.log(str);
      if (str.length >= 6) {
        console.log(111);
        this.dialogTableVisible = false;
        setTimeout(() => {
          this.dialogTableVisible1 = true;
        }, 4000);
      }
    },
    getPwd1(str) {
      console.log(str);
      if (str.length >= 6) {
        this.dialogTableVisible1 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logout {
  min-height: 585px;
  // display: flex;
  // justify-content: space-between;
}

::v-deep .el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}

::v-deep .el-input {
  width: 358px;
  height: 42px;
}

::v-deep .el-tabs__content {
  // height: 602px;
  // background-color: #4cb2fc;s
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  // color: #0294ff;
  line-height: 60px;
  height: 60px;
}

.center {
}

.from_tab {
  // height: 662px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-bottom: 17px;

  .tianjia {
    // background-color: pink;
    height: 275px;
    margin: 155px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .kuang_yinhang {
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/yinhang.png") no-repeat;
      margin-bottom: 10px;
      background-size: 200px 200px;
      // border: 1px solid black;
    }

    .kuang_pingtai {
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/pingtai.png") no-repeat center
        center;
      background-size: 150px 200px;
      margin-bottom: 10px;
    }

    .no {
      width: 90px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-bottom: 40px;
      line-height: 9px;
    }

    .btn {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      text-align: center;
      line-height: 34px;
      color: #ffffff;
      margin-top: 30px;
    }

    .btn1 {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      text-align: center;
      line-height: 34px;
      color: #ffffff;
      margin-top: 40px;
    }
  }

  .tianjias {
    /* display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; */

    .kuang_yinhang {
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/yinhang.png") no-repeat;
      margin-bottom: 10px;
      background-size: 200px 200px;
    }

    .kuang_pingtai {
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/pingtai.png") no-repeat center
        center;
      background-size: 150px 200px;
      margin-bottom: 10px;
    }

    .no {
      width: 90px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-bottom: 40px;
      line-height: 9px;
    }

    .btn {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      text-align: center;
      line-height: 34px;
      color: #ffffff;
      margin-top: 30px;
    }

    .btn1 {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      text-align: center;
      line-height: 34px;
      color: #ffffff;
      margin-top: 40px;
    }
  }

  .edit {
    padding: 50px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-align: center;

    form .el-form-item {
      position: relative;

      i {
        position: absolute;
        z-index: 100;
        left: 12px;
        font-size: 20px;
        color: #999999;
      }

      .el-input {
      }
    }

    .bangding {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 9px;
      margin-top: 23px;
    }

    .code-btn {
      width: 127px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      z-index: 222;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 9px;
      margin-left: 8px;
    }
  }

  .show {
    width: 100%;
    height: 100%;
    padding: 34px;
    padding-top: 0;

    .bank {
      width: 193px;
      height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 9px;
      margin-top: 33px;
    }

    .show_tianjia {
      width: 100px;
      // border: 1px solid black;
      height: 50px;
    }

    .show_list {
      width: 200px;
      height: 150px;
      border: 1px solid black;
    }

    .show_number {
      margin: 40px 20px;
      width: 270px;
      height: 23px;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 16px;
      margin-bottom: 20px;
    }

    .box-cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .banks {
        margin-top: 20px;
        height: 175px;
        background: url("../../../assets/images/bankcard.png") no-repeat;
        background-size: 100% 100%;
        box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
        border-radius: 10px;

        .yihang {
          font-size: 19px;
          display: flex;
          justify-content: space-between;
          font-family: Source Han Sans CN;
          align-items: center;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
          margin: 10px 20px;
        }

        .card-info {
          display: flex;
          justify-content: space-between;
          padding: 0 30px;
          color: #ffffff;

          .card-name {
            font-size: 15.96px;
          }

          .card-date {
            font-size: 17.02px;
          }
        }
      }

      .jiebang {
        margin-right: 20px;
        width: 70px;
        height: 30px;
        background: rgba(0, 0, 0, 0.37);
        border-radius: 15px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 8px;
      }
    }
  }
}

.manage {
  margin-top: 20px;
}

.platform {
  padding: 20px;
  display: flex;
  justify-content: center;

  .box-cards {
    width: 500px;

    .banks {
      margin-top: 30px;
      font-size: 20px;
      text-align: center;
      color: white;
      padding: 20px 40px;
      background: url("../../../assets/images/bankcard.png") no-repeat;
      background-size: 100% 100%;
      box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
      border-radius: 10px;
      height: 200px;
    }
  }
}

.bankName {
  display: inline-block;
  width: 200px;
  word-break: keep-all;
  /* 不换行 */
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 内容超出宽度时隐藏超出部分的内容 */
  text-overflow: ellipsis;
  /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
  background: none;
  border: none;
  text-align: left;
  font-size: 18px;
  color: white;
}

::v-deep {
  .error-password .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left i {
    font-size: 40px;
    color: #fb4d4d;
    margin-right: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flexs {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Application {
  margin-bottom: 20px;
}

.Applications {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 20px;
}

.icons {
  cursor: pointer;
}
</style>